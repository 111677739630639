<div mat-dialog-content class="content_main-container timeout-container">
  <h4 class="heading-3" *ngIf="timeleft > 0">
    {{ 'COMMON.TIMEOUT.TITLE' | translate }}
  </h4>
  <h4 class="heading-3" *ngIf="timeleft <= 0">
    {{ 'COMMON.TIMEOUT.SESSION_EXPIRED' | translate }}
  </h4>
  <span class="mat-body-2 mt-8 mb-24 text-dark-charcoal" *ngIf="timeleft > 0">
    {{ "COMMON.TIMEOUT.DESCRIPTION" | translate }}
  </span>
  <span class="mat-body-2 mt-8 mb-24 text-dark-charcoal" *ngIf="timeleft <= 0">
    {{
      (isCheckout ? 'COMMON.TIMEOUT.RETURN_TO_CHECKOUT' : 'COMMON.TIMEOUT.RETURN_TO_LOGIN')
        | translate : { merchantName: this.merchantName }
    }}
  </span>
  <span class="timeout-timer mb-24" *ngIf="timeleft > 0">
    {{ minutes }}:{{ seconds }}
  </span>
  <div class="flex-col" [ngClass]="'flex-end'">
    <button class="timeout-btn" mat-flat-button color="primary" [mat-dialog-close]="true" *ngIf="timeleft > 0">
      {{ 'COMMON.TIMEOUT.COMTINUE' | translate }}
    </button>
    <button class="timeout-btn" mat-flat-button color="primary" [mat-dialog-close]="true" *ngIf="timeleft <= 0">
      {{
        (isCheckout ? 'COMMON.TIMEOUT.RETURN_TO_CHECKOUT_BUTTON' : 'COMMON.TIMEOUT.RETURN_TO_LOGIN_BUTTON') | translate
      }}
    </button>
  </div>
</div>
