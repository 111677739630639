import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PBBEvents } from '@core/constants/events';
import { WindowService } from '@core/services/window.service';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { selectMerchantName } from '@store/checkout/checkout.selectors';
import { map, withLatestFrom } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'pbb-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, NgIf, NgClass, MatButtonModule, TranslateModule],
})
export class TimeoutComponent implements OnInit, OnDestroy {
  timeleft: number = 0;
  minutes: string = '';
  seconds: string = '';
  isCheckout: boolean = false;
  merchantName: string = '';
  private timer?: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private windowService: WindowService,
    private readonly store: Store,
    private translationService: TranslateService
  ) {
    this.isCheckout = data.isCheckout;
  }

  //
  ngOnInit(): void {
    this.timeleft = this.data.timer / 1000;
    this.calculateTime();
    if (this.isCheckout) {
      this.store
        .select(selectMerchantName)
        .pipe(
          withLatestFrom(this.translationService.get('COMMON.MERCHANT')),
          map(([value, constant]) => {
            return value ? value : constant;
          })
        )
        .subscribe((value) => {
          this.merchantName = value;
        });
    }
    this.timer = setInterval(() => {
      if (this.timeleft === 0) {
        this.timer && clearInterval(this.timer);
      } else {
        this.timeleft && this.timeleft--;
        this.calculateTime();
      }
      this.cdRef.detectChanges();
    }, 1000);
  }

  calculateTime():void {
    this.minutes = Math.floor(this.timeleft / 60) + '';
    this.seconds = ('00' + (this.timeleft % 60)).slice (-2);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    if (this.timeleft <= 0 && this.isCheckout) {
      this.windowService.sendToMerchant(PBBEvents.konekCancelled, true);
    }
  }
}
